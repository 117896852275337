import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { GlobalProvider } from './context/GlobalContext';
import Loader from './shared/Loader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// FontAwesome icons
import { library } from '@fortawesome/fontawesome-svg-core'

// import your icons
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import ErrorBoundary from './ErrorBoundary';
import { API_URL } from './config';


// Lazy-loaded modules
const UserModule = React.lazy(() => import('./user/UserModule'));
const HotelManagerModule = React.lazy(() => import('./hotelManager/HotelManagerModule'));
const AdminModule = React.lazy(() => import('./admin/AdminModule'));

library.add(fab, fas, far)
function App() {
  console.log(process.env)
  return (
    <GlobalProvider>
      <BrowserRouter>
        <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
        <Loader />
        <div className="App">
          <ErrorBoundary>
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route path="/admin/*" element={<AdminModule />} />
                <Route path="/hotel-manager/*" element={<HotelManagerModule />} />
                <Route path="/*" element={<UserModule />} />
              </Routes>
            </Suspense>
          </ErrorBoundary>
        </div>
      </BrowserRouter>
    </GlobalProvider>
  );
}

export default App;
