import { createContext, useContext, useState } from 'react';
import { authConstants } from '../constants/authConstant';

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  
  const getAdminToken = () => localStorage.getItem(authConstants.adminToken);
  const getUserToken = () => localStorage.getItem(authConstants.userToken);
  const getHotelManagerToken = () =>  localStorage.getItem(authConstants.hotelManagerToken);
  const [isHotelManagerLoggedIn, setIsHotelManagerLoggedIn] = useState(!!getHotelManagerToken());
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(!!getUserToken());
  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(!!getAdminToken());
  const [myHotel, setMyHotel] = useState(null);
  const [loader, setLoader] = useState(false)
  const [availablePasses, setAvailablePasses] = useState([])
  const [cart, setCart] = useState({
    subTotal: 0,
    items: {},
    date: null
  });

  const setAdminClear = () => {
    localStorage.removeItem(authConstants.adminToken)
    setIsAdminLoggedIn(false)
  }
  const setHotelManagerClear = () => {
    localStorage.removeItem(authConstants.hotelManagerToken)
    setIsHotelManagerLoggedIn(false)
  }
  const setUserClear = () => {
    localStorage.removeItem(authConstants.userToken)
    setIsUserLoggedIn(false)
  }

  const getAuthConfig = (type) => {
    const config = {
      headers: {},
    };
    
    const auth = `Bearer`
    switch (type) {
      case authConstants.adminToken:
        config.headers.Authorization = `${auth} ${getAdminToken()}`
        break; 
      case authConstants.hotelManagerToken:
        config.headers.Authorization = `${auth} ${getHotelManagerToken()}`
        break; 
      case authConstants.userToken:
        config.headers.Authorization = `${auth} ${getUserToken()}`
        break; 
    
      default:
        return null;
    }
    return config
  }

  return (
    <GlobalContext.Provider value={{ 
      isUserLoggedIn, 
      setIsUserLoggedIn, 
      isHotelManagerLoggedIn, 
      setIsHotelManagerLoggedIn, 
      isAdminLoggedIn, 
      setIsAdminLoggedIn, 
      cart, 
      setCart, 
      setHotelManagerClear, 
      setAdminClear, 
      setUserClear, 
      loader, 
      setLoader,
      getAuthConfig,
      myHotel,
      setMyHotel,
      setAvailablePasses,
      availablePasses
    }}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalStore = () => useContext(GlobalContext);
