import React from 'react'
import Loader from './shared/Loader';


// Error Boundary Component
function ErrorBoundary({ children }) {
  return (
    <React.Suspense fallback={<Loader />}>
      {children}
    </React.Suspense>
  );
}


export default ErrorBoundary

